.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--tefal-primary);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
  border-radius: 20px;
  padding: 10px 20px;
  color: var(--tefal-secondary);
  font-size: 1.2rem;
  text-align: start;
  margin-bottom: 10px;
}

.card h4 {
  margin-top: 0;
  margin-bottom: 2px;
}

.card .days span {
  font-size: 1rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--tefal-secondary);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--tefal-primary);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--tefal-secondary);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--tefal-primary);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.delete-btn {
  background-color: var(--tefal-secondary);
  color: var(--tefal-primary);
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 10px;
  text-transform: uppercase;
}

.error {
  color: var(--tefal-primary);
  font-weight: bold;
}

@media(max-width: 460px) {
  .card {
    display: block;
    text-align: center;
  }
  .card .info {
    margin-bottom: 20px;
  }
}