.container {
  min-height: 100vh;
  /* background: url("../../assets/images/background.jpg"); */
  background-size: cover;
  background-position: bottom center;
}

.bar {
  height: 50px;
  width: 100%;
  background-color: var(--tefal-primary);
  margin-bottom: 80px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 50px);
  text-align: center;
}

.logo {
  width: 80%;
  max-width: 300px;
  margin-bottom: 50px;
}

.dotted-line {
  width: 80%;
  max-width: 600px;
  margin-bottom: 50px;
}

.power {
  padding: 15px 25px;
  background-color: var(--tefal-secondary);
  border: 1px solid var(--tefal-primary);
  width: 80%;
  max-width: 150px;
  border-radius: 20px;
  margin: 0 auto;
  margin-bottom: 50px;
  cursor: pointer;
  position: relative;
}

.power .disable {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: grey;
  opacity: .5;
  border-radius: 20px;
}

.power img {
  max-width: 75px;
}

.line {
  height: 1px;
  width: 50%;
  background-color: var(--tefal-primary);
  margin: 0 auto;
  /* opacity: 0.18; */
  margin-bottom: 50px;
}

.time {
  max-width: 90%;
  margin: 0 auto;
}

.from-time,
.to-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--tefal-primary);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
  border-radius: 20px;
  padding: 10px 30px;
  margin-bottom: 20px;
}

.from-time div,
.to-time div {
  display: flex;
  align-items: center;
}

.from-time div img,
.to-time div img {
  margin-right: 10px;
}

.from-time div p,
.to-time div p {
  color: var(--tefal-secondary);
  font-size: 18px;
}

.from-time input,
.to-time input {
  background: unset;
  border: none;
  color: var(--tefal-secondary);
  font-size: 18px;
  font-family: inherit;
}

.from-time input:focus,
.to-time input:focus {
  outline: none;
}

.from-time input::-webkit-calendar-picker-indicator,
.to-time input::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
  -webkit-appearance: none;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  text-align: center;
  padding: 15px 70px;
  color: var(--tefal-secondary);
  border-radius: 50px;
  margin: 0 auto;
  margin-bottom: 50px;
  cursor: pointer;
  text-transform: uppercase;
  background-color: var(--tefal-primary);
}

/* .btn.start {
  background-color: #1b7c47;
}

.btn.stop {
  background-color: var(--tefal-primary);
} */

.btn p {
  margin: 0;
}

.btn img {
  margin-right: 10px;
}

.day-selector {
  color: var(--tefal-primary);
  margin-bottom: 50px;
}

.day-selector .days {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.day-selector .days div {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  border-radius: 50%;
  border: 1px solid var(--tefal-primary);
  padding: 10px;
  cursor: pointer;
  flex: 0 0 20px;
  margin-bottom: 10px;
}

.day-selector .days div.selected {
  background-color: var(--tefal-primary);
  color: var(--tefal-secondary);
}

.loading-status {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.error {
  color: var(--tefal-primary);
  font-weight: bold;
}

.view-hide-tasks {
  position: fixed;
  top: 70px;
  right: 20px;
  background-color: var(--tefal-primary);
  color: var(--tefal-secondary);
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}